<template>
	<div class="container-md text-center">
		<h2 class="text-center">Reglas de automatización</h2>
			<form id="form" @submit.prevent="submit" class="needs-validation">
				<div class="row">
					<div class="col-sm">
						<fieldset class="scheduler-border">
							<legend class="scheduler-border text-primary">Aforo</legend>
								<div class="form-group row">
									<label for="regla_a" class="col-sm-4 col-form-label"><b>Regla A</b></label>
									<label class="col-sm-2 col-form-label">aforo</label>
									<label class="col-sm-2 col-form-label"><b>></b> </label>
									<div class="col-sm-4">
										<input 
											type="number"
											min="0.1" max="5.1"
											class="form-control" 
											id="regla_a" 
											v-model="reglas.a"
											required
										>
									</div>
								</div>
								<div class="form-group row">
									<label for="regla_a2" class="col-sm-4 col-form-label"><b>Regla A2</b></label>
									<label class="col-sm-2 col-form-label">aforo</label>
									<label class="col-sm-2 col-form-label"><b>></b> </label>
									<div class="col-sm-4">
										<input 
											type="number"
											min="0.1" max="5.1"
											class="form-control" 
											id="regla_a2" 
											v-model="reglas.a2"
											required
										>
									</div>
								</div>
								<div class="form-group row">
									<label for="regla_a3" class="col-sm-4 col-form-label"><b>Regla A3</b></label>
									<label class="col-sm-2 col-form-label">aforo</label>
									<label class="col-sm-2 col-form-label"><b>></b> </label>
									<div class="col-sm-4">
										<input 
											type="number"
											min="0.1" max="5.1"
											class="form-control" 
											id="regla_a3" 
											v-model="reglas.a3"
											required
										>
									</div>
								</div>
						</fieldset>
					</div>
					<div class="col-sm">
						<fieldset class="scheduler-border">
							<legend class="scheduler-border text-primary">Ubicación</legend>
							<div class="form-group row">
								<label for="regla_b" class="col-sm-4 col-form-label"><b>Regla B</b></label>
								<label class="col-sm-2 col-form-label">ubicación</label>
								<label class="col-sm-2 col-form-label"><b>=</b> </label>
								<div class="col-sm-4">
									<input 
										type="text" 
										class="form-control" 
										id="regla_b" 
										v-model="reglas.b"
										required
										readonly
									>
									<button type="button" class="btn btn-info" @click="verentidades=true">Cambiar</button>
								</div>
							</div>
							<div class="scroll">
								<ul class="list-group" v-for="item of entidadesNameSelect">
									<li class="list-group-item">{{item}}</li>
								</ul>
							</div>
						</fieldset>
					</div>
				</div>

				<div class="row">
					<div class="col-sm">
						<fieldset class="scheduler-border">
							<legend class="scheduler-border text-primary">Score</legend>
							<div class="form-group row">
								<label for="regla_c" class="col-sm-4 col-form-label"><b>Regla C</b></label>
								<label class="col-sm-2 col-form-label">score</label>
								<label class="col-sm-2 col-form-label"><b> ></b> </label>
								<div class="col-sm-4">
								<input 
									type="number"
									min="400" max="850"
									class="form-control" 
									id="regla_c" 
									v-model="reglas.c"
									required
								>
								</div>
							</div>
						</fieldset>
					</div>
					<div class="col-sm">
						<fieldset class="scheduler-border">
							<legend class="scheduler-border text-primary">Pti</legend>
							<div class="form-group row">
								<label for="regla_d" class="col-sm-4 col-form-label"><b>Regla D</b></label>
								<label class="col-sm-2 col-form-label">pti</label>
								<label class="col-sm-2 col-form-label"><b> <= </b> </label>
								<div class="input-group col-sm-4">

								<input 
									type="number"
									min="10" max="90"
									class="form-control" 
									id="regla_d" 
									v-model="reglas.d"
									required
								>
									<div class="input-group-prepend">
										<span class="input-group-text">%</span>
									</div>
								</div>
							</div>
						</fieldset>
					</div>	
				</div>

				<div class="row">
					<div class="col-sm">
						<fieldset class="scheduler-border">
							<legend class="scheduler-border text-primary">Monto solicitado</legend>

							<div class="form-group row">
								<label for="regla_e" class="col-sm-2 col-form-label"><b>Regla E</b></label>
								<label class="col-sm-1 col-form-label">monto</label>
								<label class="col-sm-1 col-form-label"> <b><=</b> </label>
								<div class="input-group col-sm-3">
									<div class="input-group-prepend">
										<span class="input-group-text">$</span>
									</div>
									<input 
										type="number" 
										min="200000" max="5000000"
										class="form-control" 
										id="regla_e" 
										v-model="reglas.e"
										required
									>
								</div>
							</div>
							<div class="form-group row">
								<label for="regla_e2" class="col-sm-2 col-form-label"><b>Regla E2</b></label>
								<label class="col-sm-1 col-form-label">monto</label>
								<label class="col-sm-1 col-form-label"><b> > </b></label>
								<div class="input-group col-sm-3">
									<div class="input-group-prepend">
										<span class="input-group-text">$</span>
									</div>
									<input 
										type="number" 
										min="200000" max="5000000"
										class="form-control" 
										id="regla_e2" 
										v-model="reglas.e2"
										required
									>
								</div>

								<label class="col-sm-1 col-form-label"><strong> &&</strong> </label>
								<label for="regla_e2_2" class="col-sm-1 col-form-label">monto</label>
								<label class="col-sm-1 col-form-label"><b><=</b> </label>
								<div class="input-group col-sm-2">
									<div class="input-group-prepend">
										<span class="input-group-text">$</span>
									</div>
									<input 
										type="number" 
										min="200000" max="5000000"
										class="form-control" 
										id="regla_e2_2" 
										v-model="reglas.e2_2"
										required
									>
								</div>
							</div>
							<div class="form-group row">
								<label for="regla_e3" class="col-sm-2 col-form-label"><b>Regla E3</b></label>
								<label class="col-sm-1 col-form-label">monto</label>
								<label class="col-sm-1 col-form-label"> <b>></b> </label>
								<div class="input-group col-sm-3">
									<div class="input-group-prepend">
										<span class="input-group-text">$</span>
									</div>
									<input 
										type="number"
										class="form-control" 
										id="regla_e3" 
										v-model="reglas.e3"
										required
									>
								</div>
							</div>
						</fieldset>
					</div>
				</div>

				<button type="submit" class="btn btn-primary mb-2">Guardar</button>
			</form>

			<Modal v-if="verentidades" :options="modalConfig" @close="verentidades=false" class="bpb-modal">
				<h2 class="title">Estados</h2>
				<div class="form-group row">
					<label class="col-sm-4 col-form-label"><b>Seleccione los Estados</b></label>
				</div>
				
				<div class="row justify-content-center">
					<select class="w-75" v-model="entidadesSelect" multiple style="height:300px">
						<option v-for="(entidad, index) of entidades" :key="entidad.id" :value="entidad.id">
							{{entidad.nombre}}
						</option>
					</select>
				</div>
				
				<!--<div class="row justify-content-center">
    				<div class="col-5" v-for="(entidad, index) of entidades" :key="entidad.id">
						
						<div class="custom-control custom-checkbox float-left">
							<input 
								type="checkbox" 
								class="custom-control-input" 
								:id="'customCheck'+entidad.id" 
								:v-model="entidadesSelect"
								:value="entidad.id"
							>
							<label class="custom-control-label" :for="'customCheck'+entidad.id">{{entidad.nombre}}</label>
						</div>
						
					</div>
				</div>-->
			</Modal>
	</div>
</template>

<script type="text/javascript">
	import apiComite from '@/apps/comite/api/comite'
	import apiServicios from '@/apps/comite/api/servicios'
	import Modal from '@/components/Modal'

	export default {
		components: {
			Modal
		},
		
		data: () => ({
			verentidades: false,
			entidades: null,
			entidadesNameSelect:[],
			entidadesSelect: [],
			modalConfig: {
				width: '50vw',
				close: true,
			},
			oldReglas: null,
			reglas: {
				a:null,
				a2:null,
				a3:null,
				b:null,
				c:null,
				d:null,
				e:null,
				e2:null,
				e2_2:null,
				e3:null,
			}
		}),
		
		mounted: async function() {
			const res  = await apiServicios.get_estados()
			this.entidades = res.data.resultado
			
			const { data } = await apiComite.reglas_automatizacion();
			this.oldReglas = data

			this.asignar_valores(data)
		},

		methods: {
			formatPrice(value) {
		        let val = (value/1).toFixed(0).replace(',', '.')
		        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
		    },

			asignar_valores(data){
				data.forEach(regla => {
					if(regla.regla == "A"){					
						this.reglas.a = regla.valor 
					}
					if(regla.regla == "A2"){					
						this.reglas.a2 = regla.valor 
					}
					if(regla.regla == "A3"){					
						this.reglas.a3 = regla.valor 
					}
					if(regla.regla == "B"){					
						this.reglas.b = regla.valor
						this.entidadesSelect = regla.valor.split(",")
					}
					if(regla.regla == "C"){					
						this.reglas.c = regla.valor 
					}
					if(regla.regla == "D"){					
						this.reglas.d = regla.valor * 100
					}
					if(regla.regla == "E"){					
						this.reglas.e = regla.valor 
					}
					if(regla.regla == "E2"){
						var valores = regla.valor.split(",")		
						this.reglas.e2 = valores[0]
						this.reglas.e2_2 = valores[1] 
					}
					if(regla.regla == "E3"){					
						this.reglas.e3 = regla.valor 
					}
				});
			},

			submit: async function (event) {
				form.classList.add('was-validated');
				event.preventDefault();
          		event.stopPropagation();
				
				try{
					var status = false

					for (var regla of this.oldReglas) {
						if(regla.regla == "A" && this.reglas.a != regla.valor ){					
							await apiComite.guardar_reglas_automatizacion( regla.id, 
								{
									regla: regla.regla,
									valor: this.reglas.a,
								}
							)
							status = true
						}
						if(regla.regla == "A2" && this.reglas.a2 != regla.valor ){					
							await apiComite.guardar_reglas_automatizacion( regla.id, 
								{
									regla: regla.regla,
									valor: this.reglas.a2,
								}
							)
							status = true
						}
						if(regla.regla == "A3" && this.reglas.a3 != regla.valor ){					
							await apiComite.guardar_reglas_automatizacion( regla.id, 
								{
									regla: regla.regla,
									valor: this.reglas.a3,
								}
							)
							status = true
						}
						if(regla.regla == "B" && this.reglas.b != regla.valor ){
							await apiComite.guardar_reglas_automatizacion( regla.id, 
								{
									regla: regla.regla,
									valor: this.reglas.b,
								}
							)
							status = true
						}
						if(regla.regla == "C" && this.reglas.c != regla.valor ){
							await apiComite.guardar_reglas_automatizacion( regla.id, 
								{
									regla: regla.regla,
									valor: this.reglas.c,
								}
							)
							status = true
						}
						if(regla.regla == "D" && (this.reglas.d/100) != regla.valor ){
							await apiComite.guardar_reglas_automatizacion( regla.id, 
								{
									regla: regla.regla,
									valor: this.reglas.d / 100,
								}
							)
							status = true
						}
						if(regla.regla == "E" && this.reglas.e != regla.valor ){
							await apiComite.guardar_reglas_automatizacion( regla.id, 
								{
									regla: regla.regla,
									valor: this.reglas.e,
								}
							)
							status = true
						}
						if(regla.regla == "E2"){
							var valor = this.reglas.e2.concat(',', this.reglas.e2_2)
							if(valor != regla.valor){

								await apiComite.guardar_reglas_automatizacion( regla.id, 
									{
										regla: regla.regla,
										valor: valor,
									}
								)
								status = true
							}
						}
						if(regla.regla == "E3" && this.reglas.e3 != regla.valor){					
							await apiComite.guardar_reglas_automatizacion( regla.id, 
								{
									regla: regla.regla,
									valor: this.reglas.e3,
								}
							)
							status = true
						}
					};

					if(status){
						this.$notify({
							group: 'alert'
							,type: 'success'
							,title: 'Reglas'
							,text: 'Datos guardados...'
							,duration: 4000
						})
					}
					
				}catch(err){

					if(err.response?.status == 401){
						this.$notify({
							group: 'alert'
							,type: 'error'
							,title: 'Error'
							,text: 'Acceso no autorizado'
							,duration: 4000
						})
					}else
					if(err.response?.status == 400){
						this.$notify({
							group: 'alert'
							,type: 'error'
							,title: 'Error'
							,text: err.response?.data?.message
							,duration: 4000
						})
					}else{
						this.$notify({
							group: 'alert'
							,type: 'error'
							,title: 'Error'
							,text: 'Ocurrió un error, favor de intentar más tarde.'
							,duration: 4000
						})
					}
					
				}
			},

			nuevas_entidades(newEntidades){
				this.entidadesNameSelect = []
				this.entidades.forEach(entidad => {
					newEntidades.forEach(newEntidad =>{ 
						if(entidad.id == newEntidad){
							this.entidadesNameSelect.push(entidad.nombre)
						}
					})
				})

				this.reglas.b = this.entidadesSelect.toString()
			},

			
		},
		watch: {
			entidadesSelect: function (newEntidades, oldVal) {
				this.nuevas_entidades(newEntidades)
			},
		}
	}
</script>

<style>

	fieldset.scheduler-border {
		margin: 0 0 1.5em 0 !important;
		-webkit-box-shadow: 0px 0px 0px 0px #000;
		box-shadow: 0px 0px 0px 0px #000;
	}

	legend.scheduler-border {
		font-size: 1.2em !important;
		font-weight: bold !important;
		text-align: left !important;
		width: auto;
		padding: 0 10px;
		border-bottom: none;
		margin-top: -15px;
		background-color: white;
		color: black;
	}

	div.scroll {
		background-color: lightblue;
		width: 100%;
		height: auto;
		max-height: 100px!important;
		overflow: scroll;
	}
</style>